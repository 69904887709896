<template>
    <a-layout class="RecruitmentWebAdmin">
        <a-layout>
            <a-layout-header class="app-header">
                <div class="logo">
                    <a href="/"
                        ><img src="../assets/images/ic_logo_white.png" alt=""
                    /></a>
                </div>
                <a-menu
                    theme="dark"
                    mode="horizontal"
                    :defaultSelectedKeys="[this.$route.path]"
                    :style="{ lineHeight: '64px' }"
                >
                    <a-menu-item key="/dashboard">
                        <router-link
                            :to="{ path: '/dashboard' }"
                            class="nav-link"
                        >
                            <a-icon type="dashboard" />
                            <span>Dashboard</span>
                        </router-link>
                    </a-menu-item>
                    <a-sub-menu>
                        <span slot="title" class="submenu-title-wrapper"
                            ><a-icon type="question-circle" />Ứng viên</span
                        >
                        <a-menu-item key="/candidate">
                            <router-link
                                :to="{ path: '/candidate' }"
                                class="nav-link"
                            >
                                <span>Danh sách hồ sơ ứng viên</span>
                            </router-link>
                        </a-menu-item>
                        <a-menu-item key="/candidate-online">
                            <router-link
                                :to="{ path: '/candidate-online' }"
                                class="nav-link"
                            >
                                <span>Danh sách chờ phỏng vấn online</span>
                            </router-link>
                        </a-menu-item>

                        <a-menu-item key="/map-candidate-to-salon">
                            <router-link
                                :to="{ path: '/map-candidate-to-salon' }"
                                class="nav-link"
                            >
                                <span>Map ứng viên vào salon</span>
                            </router-link>
                        </a-menu-item>

                        <a-menu-item key="/candidate-in-salon">
                            <router-link
                                :to="{ path: '/candidate-in-salon' }"
                                class="nav-link"
                            >
                                <span>Ứng viên phỏng vấn tại salon</span>
                            </router-link>
                        </a-menu-item>

                        <a-menu-item key="/candidate-fail">
                            <router-link
                                :to="{ path: '/candidate-fail' }"
                                class="nav-link"
                            >
                                <span>Danh sách ứng viên không đạt</span>
                            </router-link>
                        </a-menu-item>

                        <a-menu-item key="/candidate/form">
                            <router-link
                                :to="{ path: '/candidate/form' }"
                                class="nav-link"
                            >
                                <span>Tạo mới hồ sơ ứng viên</span>
                            </router-link>
                        </a-menu-item>
                        <a-menu-item key="/candidate/import">
                            <router-link
                                :to="{ path: '/candidate/import' }"
                                class="nav-link"
                            >
                                <span>Nhập hồ sơ ứng viên</span>
                            </router-link>
                        </a-menu-item>
                    </a-sub-menu>
                </a-menu>
                <div class="header-right">
                    <a-dropdown>
                        <a-menu slot="overlay">
                            <a-menu-item @click="logout" key="3"
                                ><a-icon type="user" />Đăng xuất</a-menu-item
                            >
                        </a-menu>
                        <a-button style="margin-left: 8px"
                            >Xin chào
                            <strong style="margin: 0 10px">{{
                                getUser.fullName
                            }}</strong>
                            <a-icon type="down" />
                        </a-button>
                    </a-dropdown>
                </div>
            </a-layout-header>
            <!-- page content -->
            <router-view></router-view>
            <HistoryInterview />
        </a-layout>
    </a-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import HistoryInterview from '../components/HistoryInterview';

export default {
    name: 'RecruitmentWebAdmin',
    components: { HistoryInterview },
    data() {
        return {
            collapsed: false,
        };
    },
    computed: {
        ...mapGetters('authenticate', ['getUser']),
    },
    methods: {
        logout: async () => {
            // eslint-disable-next-line no-console,no-undef
            await ssoLogout();
        },
    },
};
</script>

<style lang="scss">
.RecruitmentWebAdmin {
    background-color: #31343d;

    .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    .app-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 25px;
    }

    .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected,
    .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-actived {
        background-color: transparent;
    }

    .header-right {
        text-align: right;
        width: 100%;
    }

    .logo img {
        height: 58px;
        margin-bottom: 10px;
        margin-right: 30px;
    }

    .ant-spin-nested-loading > div > .ant-spin {
        max-height: calc(100vh - 120px);
    }
}
</style>
