<template>
    <a-modal
        title="Lịch sử phỏng vấn ứng"
        :visible="isShow"
        :confirm-loading="isLoading"
        @cancel="closeModal"
    >
        <template slot="footer">
            <a-button
                key="submit"
                type="primary"
                :loading="isLoading"
                @click="closeModal"
            >
                OK
            </a-button>
        </template>
        <a-spin :spinning="isLoading">
            <div v-if="isEmptyObject(historyCandidate)">Không có dữ liệu</div>
            <div v-else>
                <a-timeline>
                    <a-timeline-item
                        v-for="(timeReview, name) in historyCandidate"
                        :key="name"
                    >
                        <p>{{ `Phỏng vấn lần: ${name}` }}</p>
                        <template v-if="timeReview.length !== 0">
                            <a-timeline>
                                <a-timeline-item
                                    v-for="(history, nameRound) in timeReview"
                                    :key="history.id"
                                >
                                    <p>{{ nameRound }}</p>
                                    <a-timeline>
                                        <a-timeline-item
                                            v-for="item in history"
                                            :key="item.id"
                                        >
                                            <p>
                                                {{
                                                    `Ngày tạo: ${formatDate(
                                                        item.createdDate
                                                    )}`
                                                }}
                                                -
                                                {{
                                                    `AdminId: ${
                                                        item.adminId
                                                    }`
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    `Trạng thái: ${
                                                        item.status.name
                                                    }`
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    `Ghi chú: ${item.adminNote ||
                                                        ''} `
                                                }}
                                            </p></a-timeline-item
                                        >
                                    </a-timeline>
                                </a-timeline-item>
                            </a-timeline>
                        </template>
                    </a-timeline-item>
                </a-timeline>
            </div>
        </a-spin>
    </a-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { HISTORY_SET_ID_CANDIDATE } from '../store/mutation-types';
import { formatDate } from '../utils/Date';
import { RepositoryFactory } from '../repository/RepositoryFactory';
const candidateRepository = RepositoryFactory.get('candidate');

var _ = require('lodash');

export default {
    data() {
        return {
            isShow: false,
            isLoading: false,
            historyCandidate: {},
            formatDate,
        };
    },

    created() {},

    computed: {
        ...mapState('historyInterview', ['idCandidate']),
    },

    watch: {
        idCandidate: function(newVal, oldVal) {
            if (newVal != null && oldVal == null) {
                this.isShow = true;
                this.fetchHistoryCandidate();
            }
        },
    },

    methods: {
        ...mapMutations('historyInterview', {
            setIDCandidate: HISTORY_SET_ID_CANDIDATE,
        }),
        isEmptyObject(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        },
        async fetchHistoryCandidate() {
            this.isLoading = true;
            try {
                const res = await candidateRepository.fetchHistory(
                    this.idCandidate
                );
                const { data } = res;
                let historyFormat = _.groupBy(
                    data.data,
                    (history) => history.reviewTimes
                );
                for (const property in historyFormat) {
                    historyFormat[property] = _.groupBy(
                        historyFormat[property],
                        (history) => history.round.name
                    );
                }
                this.historyCandidate = historyFormat;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },

        closeModal() {
            this.historyCandidate = [];
            this.isLoading = false;
            this.isShow = false;
            this.setIDCandidate(null);
        },
    },
};
</script>

<style lang="scss">
.ant-timeline-item-last .ant-timeline-item-tail {
    display: block;
}
</style>
